"use client";

import React from "react";

// eslint-disable-next-line no-restricted-imports
import { ToastContainer as JUXToastContainer } from "@jasperlabs/jux-next";

export const ToastContainer = () => (
	<JUXToastContainer
		containerId="app-toast"
		position="top-center"
		className="pointer-events-none mt-20 flex !w-full max-w-lg flex-col gap-3"
		limit={1}
	/>
);
